import React, { useContext, useEffect, useState } from "react";
import "./Home.css";
import Section1 from "./section1/section1";
import Section2 from "./section2/section2";
import Section3 from "./section3/section3";
import Section4 from "./section4/section4";
import Section5 from "./section5/section5";
import Footer from "../Footer/Footer";
import TradeToEarnBanner from "./TradeToEarnBanner/TradeToEarnBanner";
import Modal from "../videoModal";
import { Popup } from "../../utils/PopupContest";
import LogoutPopup from "../updated/shared/LogoutPopup/LogoutPopup";
// import MeetingBanner from "./MeetingBanner/MeetingBanner";

const Home = () => {
  const popupDetails = useContext(Popup);
  const [show, setShow] = useState(popupDetails?.popup);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);

  useEffect(() => {
    const shortToken = localStorage.getItem("shortToken");
    const access_token = localStorage.getItem("access_token");

    if (!shortToken && access_token) {
      setShowLogoutPopup(true);
    }
  }, []);

  useEffect(() => {
    setShow(popupDetails?.popup);
    console.log(popupDetails?.popup);
  }, [popupDetails]);
  return (
    <>
      <Section1 />
      {/* <Section2 /> */}
      {/* <MeetingBanner /> */}
      <TradeToEarnBanner />
      {/* <Section3 /> */}
      <Section4 />
      <Section5 />
      <Footer helpIcon={false} />
      <Modal show={show} onClose={setShow} />

      {showLogoutPopup && (
        <LogoutPopup onClose={() => setShowLogoutPopup(false)} />
      )}
    </>
  );
};

export default Home;
