import React from "react";
// import { baseDEXURL,baseCEXURL } from "../../../services/api";
import "./TradeToEarnBanner.css";
import { baseCEXURL } from "../../../services/api";
import { Button, useMediaQuery, useTheme } from "@mui/material";
// import banner from "../../../assets/SoRekt.png"
import bitcoinLogo from "../../../assets/banners/bitcoin_logo.svg";
import bitcoinText from "../../../assets/banners/bitcoin_text.svg";

import wibsLogo from "../../../assets/banners/wibs_logo.svg";
import wibsText from "../../../assets/banners/wibsTextUpdated.svg";

import shopText from "../../../assets/homeSlider/indexShopText.svg";
import shopImage from "../../../assets/homeSlider/indexShopImg.svg";
import shopImageLight from "../../../assets/homeSlider/shopPersonLightMode.png";
import shopTextLight from "../../../assets/homeSlider/shopTextLightMode.svg";

import newBannerText from "../../../assets/banners/new_updated_banner_textt.svg";
import newBannerTextDarkMode from "../../../assets/banners/satoshiTextDarkMode.svg";
import newBannerGraphic from "../../../assets/banners/new_banner_graphic.png";

import btc_text from "../../../assets/banners/backToCommunityText.svg";
import btc_text_Dark_mode from "../../../assets/backToCommunity/backToCommunityDarkModeText.svg";
import btc_logo from "../../../assets/banners/backToCommunityImg.svg";

import inexLogo from "../../../assets/banners/inex_logo.svg";
import inexText from "../../../assets/banners/inex_text.svg";

import powerLogo from "../../../assets/banners/powerpack_logo.svg";
import powerText from "../../../assets/banners/powerpack_text.svg";
import { Link } from "react-router-dom";
import HomeSlider from "./BackToCommunitySlider";
import TestimonialSlider from "./TestimonialSlider";
import TestimonialBanner from "../../../assets/homeSlider/testimonialBanner.png";

import LottoTextDarkMode from "../../../assets/banners/lottoTextDarkMode.svg";
import LottoTextLightMode from "../../../assets/banners/lottoTextLightMode.svg";
import LottoImageMode from "../../../assets/banners/lottoImage.png";

import hiveTextDarkMode from "../../../assets/banners/hiveTextDarkMode.svg";
import hiveTextLightMode from "../../../assets/banners/hiveTextLightMode.svg";
import hiveImage from "../../../assets/banners/hiveImage.png";

import smartCryptoTextDarkMode from "../../../assets/updated/homePage/smartCryptoDarkModeText.svg";
import smartCryptoTextLightMode from "../../../assets/updated/homePage/smartCryptoLightModeText.svg";

import smartApyTextLightMode from "../../../assets/updated/homePage/smartApyTextLightMode.svg";
import smartApyTextDarkMode from "../../../assets/updated/homePage/smartApyTextDarkMode.svg";
import smartApyImage from "../../../assets/updated/homePage/smartApyImage.svg";

import BrianImg from "../../../assets/updated/homePage/smartCryptoImage.png";

import trumpImg from "../../../assets/updated/homePage/trumpImg.png";
import trumpTextLightMode from "../../../assets/updated/homePage/trumpLightModeText.svg";
import trumpTextDarkMode from "../../../assets/updated/homePage/trumpDarkModeText.svg";

const ClaimButton = ({ text, href }: { text: string; href: string }) => {
  return (
    <Link
      to={href}
      className="yellow_bg_btn"
      style={{ width: "283.047px", textAlign: "center" }}
    >
      {text}
    </Link>
  );
};
const TradeToEarnBanner = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const theme = useTheme();

  return (
    <>
      <div
        style={{
          background: theme.palette.mode === "dark" ? "#191A1F" : "#FCFCFC",
          margin: "50px auto 50px auto",
          paddingTop: "30px",
        }}
      >
        <div
          className="bitcoin_banner_root"
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            gap: "20px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            className="bitcoinIcon"
            style={{
              display: "flex",
              alignItems: "flex-end",
              flex: "45%",
            }}
          >
            <img src={trumpImg} alt="bitcoinLogo" style={{ width: "100%" }} />
          </div>
          <div
            className="bitcoin_right"
            style={{
              flex: "55%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <img
                src={
                  theme.palette.mode === "dark"
                    ? trumpTextDarkMode
                    : trumpTextLightMode
                }
                alt="text"
                style={{ width: "100%" }}
              />
            </div>
            <a
              href="https://cex.indexx.ai/update/home/?buyToken=INEX"
              className="blue_btn"
              style={{
                width: "283.047px",
                textAlign: "center",
                borderRadius: "10px",
                color: theme.palette.text.primary,
                marginBottom: "40px",
              }}
            >
              Buy token
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          background: theme.palette.mode === "dark" ? "#191A1F" : "#FCFCFC",
          margin: "50px auto 50px auto",
        }}
      >
        <div
          className="bitcoin_banner_root"
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <div
            className="bitcoin_right"
            style={{
              flex: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: isMobile ? "center" : "flex-start",
              alignItems: isMobile ? "center" : "flex-start",
              marginTop: isMobile ? "100px" : "0px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-start",
                marginBottom: "30px",
              }}
            >
              <img
                src={
                  theme.palette.mode === "dark"
                    ? smartApyTextDarkMode
                    : smartApyTextLightMode
                }
                alt="text"
                style={{ width: "70%" }}
              />
            </div>
            <a
              href="https://cex.indexx.ai/smart-apy"
              className="blue_btn"
              style={{
                width: "283.047px",
                textAlign: "center",
                borderRadius: "10px",
                color: theme.palette.text.primary,
                marginBottom: "40px",
              }}
            >
              Learn more
            </a>
          </div>
          <div
            className="bitcoinIcon"
            style={{
              display: "flex",
              alignItems: "flex-end",
              flex: "30%",
            }}
          >
            <img
              src={smartApyImage}
              alt="bitcoinLogo"
              style={{ width: "90%" }}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          background: theme.palette.mode === "dark" ? "#191A1F" : "#FCFCFC",
          margin: "50px auto 50px auto",
          paddingTop: "30px",
        }}
      >
        <div
          className="bitcoin_banner_root"
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            gap: "20px",
            justifyContent: "space-around",
            alignItems: "flex-end",
          }}
        >
          <div
            className="bitcoinIcon"
            style={{
              display: "flex",
              alignItems: "flex-end",
              flex: "45%",
            }}
          >
            <img src={BrianImg} alt="bitcoinLogo" style={{ width: "100%" }} />
          </div>
          <div
            className="bitcoin_right"
            style={{
              flex: "55%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <img
                src={
                  theme.palette.mode === "dark"
                    ? smartCryptoTextDarkMode
                    : smartCryptoTextLightMode
                }
                alt="text"
                style={{ width: "70%" }}
              />
            </div>
            <a
              href="https://indexx-ai.systeme.io/learning-made-easy"
              className="yellow_outline_btn"
              style={{
                width: "283.047px",
                textAlign: "center",
                borderRadius: "10px",
                color: theme.palette.text.primary,
                marginBottom: "40px",
              }}
            >
              Register Now
            </a>
          </div>
        </div>
      </div>

      {/* <div
        style={{
          background: theme.palette.mode === "dark" ? "black" : "#FAFAFA",
          padding: "50px",
          margin: "50px auto 50px auto",
        }}
      >
        <div
          className="bitcoin_banner_root"
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            gap: "20px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            className="bitcoinIcon"
            style={{
              display: "flex",
              alignItems: "center",
              flex: "30%",
            }}
          >
            <img
              src={theme.palette.mode === "dark" ? shopImage : shopImageLight}
              alt="bitcoinLogo"
              style={{ width: "90%" }}
            />
          </div>
          <div
            className="bitcoin_right"
            style={{
              flex: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <img
                src={theme.palette.mode === "dark" ? shopText : shopTextLight}
                alt="text"
                style={{ width: "90%" }}
              />
            </div>
            <a
              href="https://shop.indexx.ai"
              className="blue_btn"
              style={{
                width: "283.047px",
                textAlign: "center",
                borderRadius: "10px",
                color: theme.palette.text.primary,
              }}
            >
              Shop Now
            </a>
          </div>
        </div>
      </div> */}

      <div
        style={{
          background: theme.palette.mode === "dark" ? "#191A1F" : "#FCFCFC",
          padding: "50px 50px 0px 50px",
          margin: "50px auto 50px auto",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            width: "100%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            className="new_banner_text"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={
                theme.palette.mode === "light" ? btc_text : btc_text_Dark_mode
              }
              style={{ width: "80%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px auto 70px auto",
            }}
          >
            <a
              href="/back-to-community"
              rel="noreferrer"
              className="register-links"
            >
              Learn more{" >"}
            </a>
          </div>
          <div className="new_banner_img">
            <img src={btc_logo} style={{ width: "100%" }} alt="btc Logo" />
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          maxWidth: "1980px",
          margin: "0px auto",
          position: "relative",
        }}
      >
        <div
          className="testimonialbanner"
          style={{
            backgroundImage: `url(${TestimonialBanner})`,
            position: "relative",
          }}
        >
          {/* Gradient Overlay */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                "linear-gradient(0deg, rgba(0,0,0,0.8), rgba(0,0,0,0.1))", // Light to dark gradient
              zIndex: 1,
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              bottom: 50,
              width: "100%",
              padding: "20px 60px",
              zIndex: 2, // Place text above the gradient
            }}
          >
            <div className="testimonialtext" style={{ color: "white" }}>
              Testimonials
            </div>
            <div
              className="testimonialflex"
              style={{ display: "flex", alignItems: "center", gap: "30px" }}
            >
              <span className="testimonalsubText" style={{ color: "white" }}>
                Listen to what they say
              </span>
              <a
                href="https://www.youtube.com/watch?v=9ULdWShBz3k"
                className="white_btn"
                style={{
                  // width: "283.047px",
                  textAlign: "center",
                  borderRadius: "10px",
                }}
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeToEarnBanner;
