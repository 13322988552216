import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  dataShow: {
    opacity: "1 !important",
    visibility: "visible !important",
    "& .bnModalWrap": {
      transform: "scale(1) !important",
    },
  },
  bidsFullModal: {},
  bnMask: {
    backgroundColor: "rgba(0, 0, 0, .8)",
    alignItems: "flex-start",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 1200,
    width: "100%",
    height: "100vh",
  },
  bnTrans: {
    opacity: 0,
    transitionDuration: "250ms",
    transitionProperty: "all",
    transitionTimingFunction: "ease-in-out",
    visibility: "hidden",
  },
  bnModal: {
    "& .bnModalWrap": {
      backgroundColor: theme?.palette?.mode === "light" ? "#ffffff" : "#1e2329",
      borderRadius: "16px",
      boxShadow: "0px 3px 6px rgba(0,0,0,.04)",
      overflow: "hidden",
      position: "relative",
      transform: "scale(.9)",
      transitionDuration: "250ms",
      transitionProperty: "all",
      transitionTimingFunction: "ease-in-out",
      maxWidth: "560px",
      width: "100%",
      marginTop: "150px",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
    textAlign: "center",
    color: `${theme?.palette?.text?.primary} !important`,
    "& h3": {
      color: `${theme?.palette?.text?.primary} !important`,
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "28px",
      marginTop: "10px",
    },
    "& h4": {
      color: `${theme?.palette?.text?.primary} !important`,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      margin: "10px 0px 15px 0px",
    },
    "& p": {
      color: `${theme?.palette?.text?.primary} !important`,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
    },
  },
  btnContainer: {
    display: "flex",
    width: "100%",
    gap: "15px",
    marginTop: "25px",
  },
  cancelButton: {
    background:
      theme?.palette?.mode === "light"
        ? "#EAECEF !important"
        : "#2B3139 !important",
    color: `${theme?.palette?.text?.primary} !important`,
  },
}));

const LogoutPopup = ({ onClose }) => {
  const classes = useStyles();

  const logOutUser = () => {
    const userType =
      localStorage.getItem("userType") !== undefined
        ? String(localStorage.getItem("userType"))
        : undefined;
    localStorage.removeItem("user"); //remove one item
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("email");
    localStorage.clear(); //clear all localstorage
    console.log(userType);

    if (userType === "CaptainBee") {
      window.location.href = "/auth/login";
    } else if (userType === "HoneyBee") {
      window.location.href = "/auth/login";
    } else {
      if (window.location.pathname.includes("trade-to-earn"))
        window.location.reload();
      else window.location.href = "/auth/login";
    }

    onClose();
  };

  useEffect(() => {
    setTimeout(() => {
      logOutUser();
    }, [2000]);
  }, []);
  return (
    <div
      className={`${classes.bnTrans} ${classes.dataShow} ${classes.bnMask} ${classes.bnModal}  ${classes.bidsFullModal}`}
    >
      <div className="bnModalWrap">
        <div className={classes.contentContainer}>
          <h3>
            We are logging you out for security reasons. Please log in again to
            continue
          </h3>
          <CircularProgress sx={{ color: "#11BE6A" }} />
        </div>
      </div>
    </div>
  );
};

export default LogoutPopup;
