// import token from '../../assets/BSheader/tokens icon 1.svg';
// import token_white from '../../assets/BSheader/tokens icon  white (1).svg';

import bonusLight from "../../assets/header-icons/bonusLight.png";
import giftLight from "../../assets/header-icons/giftLight.png";
import membership from "../../assets/header-icons/membershipLight.png";
import xMarket from "../../assets/header-icons/xMarketLight.png";

import bonusDark from "../../assets/header-icons/bonusDark.png";
import giftDark from "../../assets/header-icons/giftDark.png";
import membershipDark from "../../assets/header-icons/membershipDark.png";
import xMarketDark from "../../assets/header-icons/xMarketDark.png";
import { baseCEXURL, baseLottoUrl, baseNewCEXURL } from "../../services/api";

const getAuthenticatedUrl = async (url: any) => {
  const isAuthenticated = localStorage.getItem("access_token");
  const email = localStorage.getItem("email");
  let shortToken;
  if (email) {
    shortToken = localStorage.getItem("shortToken");
  } else if (isAuthenticated) {
    shortToken = localStorage.getItem("shortToken");
  }

  if (isAuthenticated) {
    const urlObj = new URL(url, window.location.origin);
    urlObj.searchParams.set("signInToken", String(shortToken));

    // Check if the URL is for the stock-token page
    if (url.includes("/update/home/stock-token")) {
      urlObj.searchParams.set("buyToken", "AMZN");
    } else if (url.includes("/update/home")) {
      urlObj.searchParams.set("buyToken", "INEX");
    }

    return urlObj.toString();
  }

  return url;
};

const processHeaderData = async (data: any) => {
  const promises = data.map(async (item: any) => {
    if (
      item.href.startsWith(baseCEXURL) ||
      item.href.startsWith(baseLottoUrl) ||
      item.href.startsWith("https://academy.indexx.ai") ||
      item.href.startsWith("https://wallstreet.indexx.ai") ||
      item.href.startsWith("https://shop.indexx.ai") ||
      item.href.startsWith("https://whoisbitcoinsatoshi.wtf")
    ) {
      item.href = await getAuthenticatedUrl(item.href);
    }
    if (item.dropDownContent) {
      for (const content of item.dropDownContent) {
        for (const link of content.links) {
          if (
            link.href.startsWith(baseCEXURL) ||
            item.href.startsWith(baseLottoUrl)
          ) {
            link.href = await getAuthenticatedUrl(link.href);
          }
        }
      }
    }
    return item;
  });

  return Promise.all(promises);
};

let header_data = [
  {
    mainTextDesktop: "Exchange / Buy Crypto",
    mainTextMob: "Buy Crypto",
    active: false,
    href: baseNewCEXURL,
    hasMegaDrop: true,
    dropDownContent: [
      {
        heading: "Explore",
        mainList: true,
        links: [
          {
            name: "Buy and Sell",
            href: `${baseCEXURL}/update/home`,
          },
          {
            name: "Convert",
            href: `${baseCEXURL}/convert`,
          },
          {
            name: "Asset Wallet",
            href: `${baseCEXURL}/wallet/overview`,
          },
          {
            name: "Staking",
            href: `${baseCEXURL}/indexx-exchange/buy-sell/staking`,
          },
          // {
          //   name: "xTokens",
          //   href: `${baseCEXURL}/update/home`,
          // },
        ],
      },
      {
        heading: "Buy,Trade & Earn",
        links: [
          {
            name: "Buy Indexx Tokens",
            href: baseNewCEXURL,
          },
          {
            name: "Earn Apr on all Tokens",
            href: `${baseCEXURL}/indexx-exchange/buy-sell/staking`,
          },
          {
            name: "Invest in Stock Tokens",
            href: baseNewCEXURL,
          },
        ],
      },
      {
        heading: "How It Works",
        links: [
          {
            name: "How to buy Tokens",
            href: "/indexx-exchange/how-it-works/centralized",
          },
          {
            name: "How does Staking work?",
            href: `${baseCEXURL}indexx-exchange/buy-sell/staking`,
          },
          {
            name: "Where are the token Whitepapers?",
            href: "/indexx-exchange/token-details",
          },
        ],
      },
    ],
  },
  {
    mainTextDesktop: "Crypto Treasury",
    mainTextMob: "Crypto Treasury",
    active: false,
    href: "https://cex.indexx.ai/crypto-treasury",
    hasMegaDrop: true,
    dropDownContent: [
      {
        heading: "Explore Crypto Treasury",
        mainList: true,
        links: [
          {
            name: "Smart Crypto",
            href: "https://cex.indexx.ai/smart-crypto",
          },
          {
            name: "Smart APY",
            href: "https://cex.indexx.ai/smart-apy",
          },
        ],
      },
    ],
  },

  // {
  //   mainTextDesktop: "Meme",
  //   mainTextMob: "Meme",
  //   active: false,
  //   href: "https://indexx.ai/meme-details",
  //   hasMegaDrop: true,
  //   dropDownContent: [
  //     {
  //       heading: "Explore",
  //       mainList: true,
  //       links: [
  //         {
  //           name: "Meme Coins",
  //           href: "https://indexx.ai/meme-details/meme-coin",
  //         },
  //         {
  //           name: "Ordinals",
  //           href: "https://indexx.ai/meme-details/ordinal-coin",
  //         },
  //         {
  //           name: "NFTs",
  //           href: "https://indexx.ai/meme-details/nft",
  //         },
  //       ],
  //     },
  //     {
  //       heading: "Action",
  //       links: [
  //         {
  //           name: "Buy WIBS meme coins",
  //           href: "https://whoisbitcoinsatoshi.wtf/coin",
  //         },
  //         {
  //           name: "Buy Ordinals",
  //           href: "https://indexx.ai/indexx-exchange/coming-soon",
  //         },
  //         {
  //           name: "Buy NFTs",
  //           href: "https://indexx.ai/meme-details/nft",
  //         },
  //       ],
  //     },
  //     {
  //       heading: "Learn More",
  //       links: [
  //         {
  //           name: "WIBS Whitepaper",
  //           href: "https://smallpdf.com/file#s=b3cde823-96de-4a01-b35f-5fc7b06c72e1",
  //         },
  //         {
  //           name: "WIBS Audit Report",
  //           href: "https://smallpdf.com/file#s=94f05479-8ca5-4236-a44c-a977bd34230b",
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    mainTextDesktop: "Shop",
    mainTextMob: "Shop",
    active: false,
    href: "https://shop.indexx.ai/",
    hasMegaDrop: true,
    dropDownContent: [
      {
        heading: "Explore",
        mainList: true,
        links: [
          {
            name: "Gifts",
            href: "https://shop.indexx.ai/?category=gift",
          },
          {
            name: "Greeting",
            href: "https://shop.indexx.ai/?category=greeting",
          },

          {
            name: "Hive Pack",
            href: "https://shop.indexx.ai/?category=hive-pack",
          },
          {
            name: "Create Gift",
            href: "https://cex.indexx.ai/redeem/create-card",
          },
          // {
          //   name: "XMarket",
          //   href: "https://xnftmarketplace.indexx.ai/collections/bitcoin-xnft/1",
          // },
        ],
      },
      {
        heading: "Redeem",
        mainList: false,
        links: [
          {
            name: "Redeem Gift Cards",
            href: "https://cex.indexx.ai/redeem",
          },
          // {
          //   name: "Be part of Elite Club",
          //   href: `${baseCEXURL}/indexx-exchange/elite-club`,
          // },
        ],
      },
      {
        heading: "More on shop",
        mainList: false,
        links: [
          {
            name: "How to redeem gift cards?",
            href: "https://cex.indexx.ai/redeem",
          },
          // {
          //   name: "What are XNFTs?",
          //   href: "https://xnft.indexx.ai/",
          // },
          // {
          //   name: "Benefits of Membership",
          //   href: `${baseCEXURL}indexx-exchange/elite-club`,
          // },
        ],
      },
    ],
  },
  {
    mainTextDesktop: "Lotto",
    mainTextMob: "Lotto",
    active: false,
    href: "https://lotto.indexx.ai/",
    hasMegaDrop: true,
    dropDownContent: [
      {
        heading: "Explore Lotto",
        mainList: true,
        links: [
          {
            name: "About",
            href: "https://lotto.indexx.ai/about",
          },
          {
            name: "Buy Ticket",
            href: "https://lotto.indexx.ai/contest",
          },
          {
            name: "Contest",
            href: "https://lotto.indexx.ai/contest",
          },
          {
            name: "Grand Prize",
            href: "https://lotto.indexx.ai/grand-prize",
          },
          {
            name: "Winners",
            href: "https://lotto.indexx.ai/winner",
          },
        ],
      },
      {
        heading: "Rewards",
        links: [
          {
            name: "Win a Ferrari",
            href: "https://lotto.indexx.ai/grand-prize",
          },
          {
            name: "Claim Rewards",
            href: "https://lotto.indexx.ai/reward",
          },
          {
            name: "See who won",
            href: "https://lotto.indexx.ai/winner",
          },
        ],
      },
      {
        heading: "Learn More",
        links: [
          {
            name: "How does Fantasy Lotto work?",
            href: "https://lotto.indexx.ai/how-work",
          },
          {
            name: "Contact Support",
            href: "https://lotto.indexx.ai/contact",
          },
        ],
      },
    ],
  },

  // {
  //   mainTextDesktop: "Swap",
  //   mainTextMob: "Swap",
  //   active: false,
  //   href: "https://swap.indexx.ai/",
  //   hasMegaDrop: true,
  //   dropDownContent: [
  //     {
  //       heading: "Explore",
  //       mainList: true,
  //       links: [
  //         {
  //           name: "Staking",
  //           href: `${baseCEXURL}indexx-exchange/buy-sell/staking`,
  //         },
  //         {
  //           name: "Tokens",
  //           href: "https://indexx.ai/indexx-exchange/token-details",
  //         },
  //         {
  //           name: "Wallstreet",
  //           href: "https://wallstreet.indexx.ai/",
  //         },
  //         {
  //           name: "Web3 Wallet",
  //           href: "https://wallet.indexx.ai/",
  //         },
  //       ],
  //     },
  //     {
  //       heading: "Action",
  //       links: [
  //         {
  //           name: "Register on Exchange",
  //           href: `${baseCEXURL}/indexx-exchange/buy-sell/get-started/`,
  //         },
  //         {
  //           name: "Play Crypto Lottery",
  //           href: "https://lotto.indexx.ai/",
  //         },
  //         {
  //           name: "Buy Indexx Tokens",
  //           href: baseNewCEXURL,
  //         },
  //       ],
  //     },
  //     {
  //       heading: "More on Indexx",
  //       links: [
  //         {
  //           name: "White Paper",
  //           href: "/indexx-exchange/token-details",
  //         },
  //         {
  //           name: "Legal Documents",
  //           href: "/indexx-exchange/legal",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    mainTextDesktop: "Hive",
    mainTextMob: "Hive",
    active: false,
    href: "https://hive.indexx.ai/",
    hasMegaDrop: true,
    dropDownContent: [
      {
        heading: "Explore",
        mainList: true,
        links: [
          {
            name: "Hive Captain",
            href: "https://hive.indexx.ai/bees",
          },
          {
            name: "Hive Member",
            href: "https://hive.indexx.ai/honey-bees",
          },
        ],
      },
      {
        heading: "Become a member",
        links: [
          {
            name: "Register as Hive Captain",
            href: "https://hive.indexx.ai/sign-up",
          },
          {
            name: "Sign Up as Hive Member",
            href: `${baseCEXURL}/indexx-exchange/buy-sell/get-started-honeybee`,
          },
        ],
      },
      {
        heading: "Learn The Hive",
        links: [
          {
            name: "How does Hive work?",
            href: "https://hive.indexx.ai/",
          },
          {
            name: "Contact Support",
            href: "/indexx-exchange/help/contact",
          },
        ],
      },
    ],
  },
  {
    mainTextDesktop: "xTokens",
    mainTextMob: "xTokens",
    active: false,
    href: "/indexx-exchange/token-details",
    hasMegaDrop: true,
    dropDownContent: [
      {
        heading: "Explore",
        mainList: true,
        links: [
          {
            name: "Buy Token",
            href: baseNewCEXURL,
          },
          {
            name: "INEX",
            href: "/indexx-exchange/token-details/inex",
          },
          {
            name: "IN500",
            href: "/indexx-exchange/token-details/in500",
          },
          {
            name: "INXC",
            href: "/indexx-exchange/token-details/crypto",
          },
          // {
          //   name: "INXP",
          //   href: "/indexx-exchange/token-details/phoenix",
          // },
          {
            name: "IUSD+",
            href: "/indexx-exchange/token-details/usd",
          },
          {
            name: "SoRekt Token",
            href: "https://sorekt.wtf/",
          },
          {
            name: "Who is bitcoin Satoshi",
            href: "https://whoisbitcoinsatoshi.wtf",
          },
        ],
      },
      {
        heading: "Quick Links",
        links: [
          // {
          //   name: "Get Discounts",
          //   href: "/discount-shop",
          // },
          {
            name: "Get Gift Cards",
            href: "https://shop.indexx.ai/?category=gift",
          },
          {
            name: "Sign up on Exchange",
            href: `/auth/signup-email`,
          },
        ],
      },
      {
        heading: "Legalities",
        links: [
          {
            name: "White Paper",
            href: "/indexx-exchange/token-details",
          },
          {
            name: "Legal Documents",
            href: "/indexx-exchange/legal",
          },
        ],
      },
    ],
  },

  {
    mainTextDesktop: "Academy",
    mainTextMob: "Academy",
    active: false,
    href: "https://academy.indexx.ai/",
    hasMegaDrop: true,
    dropDownContent: [
      {
        heading: "Explore",
        mainList: true,
        links: [
          {
            name: "Become an Instructor",
            href: "https://academy.indexx.ai/authentication/?next=%2Fbecome-an-instructor",
          },
          {
            name: "Courses",
            href: "https://academy.indexx.ai/courses/?short=",
          },
        ],
      },
      {
        heading: "Action",
        links: [
          {
            name: "Log in",
            href: "/auth/login",
          },
          {
            name: "Register",
            href: "/auth/signup-email",
          },
        ],
      },
      {
        heading: "Opportunity",
        links: [
          {
            name: "How to become an instructor?",
            href: "https://academy.indexx.ai/authentication/?next=%2Fbecome-an-instructor",
          },
        ],
      },
    ],
  },
  // {
  //   mainTextDesktop: "WallStreet",
  //   mainTextMob: "WallStreet",
  //   active: false,
  //   href: "https://wallstreet.indexx.ai/",
  //   hasMegaDrop: true,
  //   dropDownContent: [
  //     {
  //       heading: "Explore",
  //       mainList: true,
  //       links: [
  //         {
  //           name: "Stock Tokens",
  //           href: "https://wallstreet.indexx.ai/details?type=all",
  //         },
  //         {
  //           name: "Stock Certificates",
  //           href: "https://wallstreet.indexx.ai/certificates?type=all",
  //         },
  //         {
  //           name: "ETFs",
  //           href: "https://wallstreet.indexx.ai/etf",
  //         },
  //       ],
  //     },
  //     {
  //       heading: "Action",
  //       links: [
  //         {
  //           name: "Buy Stock Tokens",
  //           href: "https://cex.indexx.ai/update/home/stock-token?buyToken=AMZN",
  //         },
  //         {
  //           name: "Buy Stock Certificates",
  //           href: " ",
  //         },
  //         {
  //           name: "Buy ETFs",
  //           href: "https://cex.indexx.ai/update/home/etf-tokens?buyToken=ALCRYP",
  //         },
  //       ],
  //     },
  //     {
  //       heading: "Learn more",
  //       links: [
  //         {
  //           name: "Learn about Stock Tokens",
  //           href: "https://wallstreet.indexx.ai/learn-tokens",
  //         },
  //         {
  //           name: "Learn about Stock Certificates",
  //           href: "https://wallstreet.indexx.ai/learn-certificates",
  //         },
  //         {
  //           name: "Learn about ETFs",
  //           href: "https://wallstreet.indexx.ai/learn-etfs",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   mainTextDesktop: "Affiliate",
  //   mainTextMob: "Affiliate",
  //   active: false,
  //   href: "https://register.affiliate.indexx.ai/",
  //   hasMegaDrop: true,
  //   dropDownContent: [
  //     {
  //       heading: "Explore",
  //       mainList: true,
  //       links: [
  //         {
  //           name: "Become an Affiliate",
  //           href: "https://register.affiliate.indexx.ai/",
  //         },
  //         {
  //           name: "Trade to Earn",
  //           href: "/indexx-exchange/trade-to-earn",
  //         },
  //       ],
  //     },
  //     {
  //       heading: "Action",
  //       links: [
  //         {
  //           name: "Become an affiliate",
  //           href: "https://register.affiliate.indexx.ai/about",
  //         },
  //       ],
  //     },
  //     {
  //       heading: "Support",
  //       links: [
  //         {
  //           name: "How to get my earnings?",
  //           href: "https://indexx.ai/indexx-exchange/how-it-works/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   mainTextDesktop: "Elite Club",
  //   mainTextMob: "Elite Club",
  //   active: false,
  //   href: `${baseCEXURL}indexx-exchange/elite-club`,
  //   hasMegaDrop: true,
  //   dropDownContent: [
  //     {
  //       heading: "Explore Club",
  //       mainList: true,
  //       links: [
  //         {
  //           name: "Funding Wallet",
  //           href: `${baseCEXURL}indexx-exchange/buy-sell/wallet`,
  //         },
  //         {
  //           name: "Power Packs",
  //           href: `${baseCEXURL}indexx-exchange/power-pack`,
  //         },
  //         {
  //           name: "Staking",
  //           href: `${baseCEXURL}indexx-exchange/buy-sell/staking`,
  //         },
  //         {
  //           name: "Web3 Wallet",
  //           href: "https://wallet.indexx.ai/login/sign-in",
  //         },
  //       ],
  //     },
  //     {
  //       heading: "Action",
  //       links: [
  //         {
  //           name: "Log in",
  //           href: baseNewCEXURL,
  //         },
  //         {
  //           name: "Register",
  //           href: `${baseCEXURL}indexx-exchange/buy-sell/get-started`,
  //         },
  //       ],
  //     },
  //     {
  //       heading: "Perks",
  //       links: [
  //         {
  //           name: "Benefits of elite club",
  //           href: `${baseCEXURL}/indexx-exchange/elite-club`,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    mainTextDesktop: "Company",
    mainTextMob: "Company",
    active: true,
    href: "https://indexx.ai",
    hasMegaDrop: true,
    dropDownContent: [
      {
        heading: "Explore",
        mainList: true,
        links: [
          {
            name: "About",
            href: "https://indexx.ai/indexx-exchange/about",
          },
          {
            name: "Blog",
            href: "https://indexx.ai/indexx-exchange/blog",
          },
          {
            name: "Careers",
            href: "https://indexx.ai/indexx-exchange/careers",
          },
          {
            name: "How it Works",
            href: "https://indexx.ai/indexx-exchange/how-it-works",
          },
          {
            name: "Markets",
            href: "https://indexx.ai/indexx-exchange/markets",
          },
          {
            name: "Vlog",
            href: "https://indexx.ai/indexx-exchange/vlog",
          },
          {
            name: "Document",
            href: "https://indexx.ai/indexx-exchange/coming-soon",
          },
          {
            name: "Whales Club",
            href: "https://indexx.ai/whales-club",
          },
          {
            name: "Elite Club",
            href: "https://indexx.ai/elite-club",
          },
          {
            name: "Back to Community",
            href: "https://indexx.ai/back-to-community",
          },
          {
            name: "Testimonials",
            href: "https://www.youtube.com/watch?v=9ULdWShBz3k",
          },
        ],
      },
      {
        heading: "More about Indexx",
        links: [
          {
            name: "Know the company",
            href: "https://indexx.ai/indexx-exchange/about",
          },
          {
            name: "Read updates",
            href: "https://indexx.ai/indexx-exchange/blog",
          },
          {
            name: "Find opportunity",
            href: "https://indexx.ai/indexx-exchange/careerst",
          },
          {
            name: "Features",
            href: "https://indexx.ai/indexx-exchange/how-it-works",
          },
          {
            name: "Crypto trends",
            href: "https://indexx.ai/indexx-exchange/markets",
          },
          {
            name: "Watch videos",
            href: "https://indexx.ai/indexx-exchange/vlog",
          },
        ],
      },
      {
        heading: "Legalities",
        links: [
          {
            name: "Legal docs",
            href: "https://indexx.ai/indexx-exchange/legal",
          },

          {
            name: "Tokens",
            href: "https://indexx.ai/indexx-exchange/token-details",
          },
        ],
      },
    ],
  },
];

const initializeHeaderData = async () => {
  header_data = await processHeaderData(header_data);
};

// Update `auth_header_data` to append the authenticated URL
export const updateAuthHeaderData = async (authHeaderData: any) => {
  return authHeaderData.map(async (item: any) => {
    if (item.mainTextDesktop === "profile" || item.mainTextMob === "profile") {
      for (const content of item.dropDownContent) {
        for (const link of content.links) {
          if (
            link.name === "Change Password" ||
            link.name === "Account and Settings" ||
            link.name === "Asset Wallet"
          ) {
            link.href = await getAuthenticatedUrl(link.href);
          }
        }
      }
    }
    return item;
  });
};

initializeHeaderData();

export const auth_header_data = [
  {
    mainTextDesktop: "Logout",
    mainTextMob: "Logout",
    img: false,
    isAuth: true,
    active: false,
    href: "#",
    hasMegaDrop: false,
    dropDownContent: [],
  },
  {
    mainTextDesktop: "profile",
    mainTextMob: "profile",
    img: true,
    isAuth: true,
    active: false,
    href: "#",
    hasMegaDrop: true,
    dropDownContent: [
      {
        heading: "Profile Information",
        mainList: true,
        links: [
          {
            name: "Asset Wallet",
            href: "https://cex.indexx.ai/wallet/smart-crypto",
          },
          {
            name: "Account and Settings",
            href: "https://cex.indexx.ai/indexx-exchange/account",
          },
          {
            name: "Change Password",
            href: "https://cex.indexx.ai/indexx-exchange/change-password",
          },
        ],
      },
    ],
  },
  {
    mainTextDesktop: "How it Works",
    mainTextMob: "How it Works",
    isAuth: false,
    active: false,
    href: "/how-master-login-works",
    hasMegaDrop: false,
    dropDownContent: [],
  },
  {
    mainTextDesktop: "Login",
    mainTextMob: "Login",
    isAuth: false,
    active: false,
    href: "/auth/login/",
    hasMegaDrop: false,
    dropDownContent: [],
  },
  {
    mainTextDesktop: "Register",
    mainTextMob: "Register",
    isAuth: false,
    active: false,
    href: "/auth/signup-email/",
    hasMegaDrop: false,
    dropDownContent: [],
  },
];

// Initialize and update `auth_header_data`
(async () => {
  const updatedAuthHeaderData = await updateAuthHeaderData(auth_header_data);
  console.log(updatedAuthHeaderData); // Log or use the updated data as needed
})();

export default header_data;
